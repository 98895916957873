import { useNavigate } from "react-router"
import "../styles/nav.css"

function Nav() {
    const navigate = useNavigate()

    function goHome(){
        navigate("/")
    }

    function goBookings(){
        navigate("/bookings")
    }

    return (
        <nav className="nav">
            <div className="nav-option" onClick={goHome}>
                <h2 className="nav-text">Home</h2>
            </div>
            <div className="nav-option" onClick={goBookings}>
                <h2 className="nav-text">Bookings</h2>
            </div>
        </nav>
    )
}

export default Nav