function MonthDropdown({setSelectedDate, selectedDate, date}) {
    
    function createOptionList(num, start) {
        const options=[]
        for(let i=0;i<num;i++){
            if(String(start+i).length===1){
                options.push(<option key={start+i}>0{start+i}</option>)
            }else{options.push(<option key={start+i}>{start+i}</option>)}
        }
        return options
    }

    function changeMonth(Event){
        setSelectedDate({...selectedDate, month: Event.target.value})
    }
    
    return (
        <select onChange={changeMonth} selected={selectedDate.month} required>
            <option value="">M</option>
            {
                date.year == selectedDate.year ? createOptionList(13-date.month, date.month):createOptionList(12, 1)
            }
        </select>
    )
}

export default MonthDropdown;