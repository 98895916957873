import React, { useState, useEffect} from 'react';

function HourDropdown({selectedDate, setSelectedDate, times, dayInfo, setDayInfo}) {
    const [hours, setHours] = useState(["", ""])    

    function dayOfWeek(){
        const dayOfWeek = new Date(selectedDate.year, selectedDate.month, selectedDate.day).getDay()
        setDayInfo(times[dayOfWeek])
    }

    function convertHours(){
       const start_time = dayInfo.start.replace('.', ':')
       const end_time = dayInfo.end.replace('.', ':')
       setHours([start_time, end_time])
    }

    useEffect(() => {
        dayOfWeek()
    }, [selectedDate]);

    useEffect(() => {
       convertHours() 
    }, [dayInfo]);
    
    function changeTime(Event){
        setSelectedDate({...selectedDate, time: Event.target.value})
    }        

    function renderHourOptions(min, max){
        const min_hour = parseInt(min.substring(0, min.indexOf(':')))
        const max_hour = parseInt(max.substring(0, max.indexOf(':')))
        var options = []
        for (let i=min_hour; i<max_hour; i++){
           options.push(<option key={i}>{i}</option>) 
        }
        return options
    }

    return (
        <select onChange={changeTime}>
            {
                hours[0] ? renderHourOptions(hours[0], hours[1]) : <></>
            }
        </select>
    )
}

export default HourDropdown;