function DayDropdown({setSelectedDate, selectedDate, date}) {
    
    function createOptionList(num, start) {
        const options=[]
        for(let i=0;i<num;i++){
            if(String(start+i).length===1){
                options.push(<option key={start+i}>0{start+i}</option>)
            }else{options.push(<option key={start+i}>{start+i}</option>)}
        }
        return options
    }

    function isToday(){
        if(date.year == selectedDate.year && date.month == selectedDate.month){
                return true 
        }
        return false
    }

    function daysInMonth() {
        return new Date(selectedDate.year, selectedDate.month, 0).getDate()
    }

    function changeDay(Event){
        setSelectedDate({...selectedDate, day: Event.target.value})
    }
    
    return (
        <select onChange={changeDay} selected={selectedDate.day} required>
            <option value="">D</option>
            {
                isToday() ? createOptionList(daysInMonth(selectedDate.month, selectedDate.year)-date.day, date.day)
                : createOptionList(daysInMonth(selectedDate, selectedDate.year), 1)
            }
        </select>
    )
}

export default DayDropdown