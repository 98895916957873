import React, { useState } from 'react';
import axios from 'axios';
import { apiKey } from '../helpers/constants';
import "../styles/bookingcard.css"

function BookingCard({ booking, name, address}) {
    const [cancelMessage, setCancelMessage] = useState(false)
    const [cancelled, setCancelled] = useState(false)

    function confirmCancel(){
        setCancelMessage(true)
    }

    async function cancelBooking(){
        let data = JSON.stringify({
            "status": "cancelled"
        })

        let config = {
            method: 'patch',
            maxBodyLength: Infinity,
            url: `https://bowtie-fe-assignment-api.onrender.com/booking/${booking.id}`,
            headers: { 
              'Content-Type': 'application/json', 
              'x-api-key': apiKey 
            },
            data : data
          }
          
          axios.request(config)
          .then((response) => {
            console.log(JSON.stringify(response.data))
            setCancelMessage(false)
            setCancelled(true)
          })
          .catch((error) => {
            console.log(error)
          })
    }

    return ( 
        <div className='booking-card'>
            <p className='booking-info-text'>Patient: {booking.name}</p>
            <p className='booking-info-text'>Doctor: {name}</p> 
            <p className='booking-info-text'>address: {`${address.district}\n${address.line_1}\n${address.line_2}`}</p>
            <p className='booking-info-text'>{booking.date}</p>
            <p className='booking-info-text'>{booking.start}:00</p>
            {
                cancelled || booking.status === "cancelled" ? (<p className='booking-info-text' id='cancelled'>CANCELLED</p>) :
                <button onClick={confirmCancel}>Cancel</button>
            }
            {
                cancelMessage? (
                    <div>
                        <p>{`Are you sure you wish to cancel ${name}, ${booking.date}, ${booking.start}?`}</p>
                        <button onClick={cancelBooking}>Confirm Cancellation</button>
                    </div>): <></>
            }
        </div> 
    );
}

export default BookingCard;