import "../styles/timecard.css"
function TimeCard({hours}) {
    return (
        <div className="day-div">
            <h3 className="day">{hours.day}</h3>
            <h4 className="times">Open: {hours.start}</h4>
            <h4 className="times">Closed: {hours.end}</h4>
        </div>
    );
}
export default TimeCard;