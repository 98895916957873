import React, { useState } from 'react';
import YearDropdown from './YearDropdown';
import MonthDropdown from './MonthDropdown'
import DayDropdown from './DayDropdown';
import HourDropdown from './HourDropdown';
import { apiKey } from '../helpers/constants';
import axios from 'axios';
import BookingErrorNoti from './BookingErrorNoti';
import BookingSuccessNoti from './BookingSuccessNoti';
import '../styles/bookingform.css'
function BookingForm({date, times, id}) {
    const [selectedDate, setSelectedDate] = useState({year: date.year, month: date.month, day:date.day, time:date.time})    
    const [dayInfo, setDayInfo] = useState(times[0])
    const [bookingSuccessful, setBookingSuccessful] = useState(false)
    const [bookingError, setBookingError] = useState(false)

    async function createBooking(Event){
        Event.preventDefault()
        let data = JSON.stringify({
          "id": "string",
          "name": selectedDate.name,
          "start": parseFloat(selectedDate.time),
          "doctorId": id,
          "date": `${selectedDate.year}-${selectedDate.month}-${selectedDate.day}`,
          "status": "confirmed"
        })
        
        let config = {
          method: 'post',
          maxBodyLength: Infinity,
          url: 'https://bowtie-fe-assignment-api.onrender.com/booking',
          headers: { 
            'Content-Type': 'application/json', 
            'x-api-key': apiKey
          },
          data : data
        }
        
        axios.request(config)
        .then((response) => {
            console.log(JSON.stringify(selectedDate))
            setBookingSuccessful(true)
        })
        .catch((error) => {
            console.log(error)
            setBookingError(true)
        })
    } 

    function updatePatientName(Event){
        setSelectedDate({...selectedDate, name: Event.target.value})
    }

    return (
        <div>        
            
            <form onSubmit={createBooking}>
                <YearDropdown setSelectedDate={setSelectedDate} selectedDate={selectedDate} date={date}/>
                <MonthDropdown setSelectedDate={setSelectedDate} selectedDate={selectedDate} date={date}/> 
                <DayDropdown setSelectedDate={setSelectedDate} selectedDate={selectedDate} date={date}/> 
                <HourDropdown setSelectedDate={setSelectedDate} selectedDate={selectedDate} times={times} dayInfo={dayInfo} setDayInfo={setDayInfo}/>
                <input placeholder="Patient's name" onChange={updatePatientName} required></input>
                <button>Book</button>
            </form>
            <p className='opening-times-noti'>This Day's Opening Times: {`${dayInfo.start.replace('.', ':')} - ${dayInfo.end.replace('.', ':')}`}</p>
            {
                bookingSuccessful ? 
                    <div>
                        <BookingSuccessNoti setBookingSuccessful={setBookingSuccessful} selectedDate={selectedDate}/>
                    </div> :
                    <></>
            }
            {
                bookingError ? 
                    <div>
                        <BookingErrorNoti setBookingError={setBookingError}/>
                    </div> :
                    <></>
            }
        </div>
    )
}

export default BookingForm