import React, { useState, useEffect } from 'react';
import BookingCard from '../components/BookingCard';
import Nav from '../components/Nav';
import axios from 'axios';
import { apiKey, apiURL } from '../helpers/constants';
import '../styles/bookings.css'

function Bookings() {
    const [bookingsList, setBookingsList] = useState([])
    const [doctorsObject, setDoctorsObject] = useState(null)

    useEffect(() => {
        async function getBookings(){
            let config = {
                method: 'get',
                url: `${apiURL}/booking`,
                headers: { 
                  'x-api-key': apiKey
                }
              }
              
            axios.request(config)
            .then((response) => {
                setBookingsList(response.data)
            })
            .catch((error) => {
                console.log(error);
            })
        }
        getBookings()
    }, []);

    useEffect(() => {
        async function getDoctors(){
            let config = {
                method: 'get',
                url: `${apiURL}/doctor`,
                headers: { 
                  'x-api-key': apiKey
                }
              }
              
            axios.request(config)
            .then((response) => {
                let doctorObj = {}
                response.data.map((doctor)=>{
                    doctorObj[doctor.id] = doctor
                })
                setDoctorsObject(doctorObj)
            })
            .catch((error) => {
                console.log(error);
            });
        }
        getDoctors()
    }, []);

    return ( 
        <>
            <Nav/>
            <div className='bookings-body'>
                {
                    doctorsObject? (
                        bookingsList.map((booking) => (
                            <BookingCard
                                key={booking.id}
                                booking={booking}
                                name={doctorsObject[booking.doctorId].name}
                                address={doctorsObject[booking.doctorId].address}
                            /> 
                        ))):(
                        <p>Loading...</p>)
                }
            </div>
        </>
     );
}

export default Bookings;