import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from './pages/Home.js'
import Bookings from "./pages/Bookings";

function App() {
  return (
      <Router>
        <Routes>
          <Route path="/" element={<Home/>} />
          <Route path="/bookings" element={<Bookings/>} />
        </Routes>
      </Router>
      );
}

export default App;
