import "../styles/bookingerrornoti.css"
function BookingErrorNoti({setBookingError}) {

    function closeNoti(){
        setBookingError(false)
    }

    return (
        <div>
            <p className="error-text">Error. Please try again.</p>
            <button onClick={closeNoti}>Close</button>
        </div>
    )
}

export default BookingErrorNoti