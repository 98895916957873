import "../styles/bookingsuccessnoti.css"
function BookingErrorNoti({setBookingSuccessful, selectedDate}) {

    function closeNoti(){
        setBookingSuccessful(false)
    }

    return (
        <div>
            <p className="success-text">Booking Success!</p>
            <p className="booking-confirmed">{`${selectedDate.year}/${selectedDate.month}/${selectedDate.day} at ${selectedDate.time}`}</p>
            <button onClick={closeNoti}>Close</button>
        </div>
    )
}

export default BookingErrorNoti