function YearDropdown({setSelectedDate, selectedDate, date}) {
    
    function createOptionList(num, start) {
        const options=[]
        for(let i=0;i<num;i++){
            options.push(<option key={start+i}>{start+i}</option>)
        }
        return options
    }

    function changeYear(Event){
        setSelectedDate({...selectedDate, year: Event.target.value})
    }
    
    return (
        <select onChange={changeYear} selected={selectedDate.year} required>
            <option value="">Y</option>
            {
                createOptionList(10, date.year)
            }
        </select>
    )
}

export default YearDropdown;