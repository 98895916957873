import React, { useEffect, useState } from 'react';
import TimeCard from './TimeCard';
import '../styles/doctorcard.css'
import BookingForm from './BookingForm';
import { sortDayValues } from '../helpers/constants';

function DoctorCard({name, address, opening_hours, date, id}) {
    const [sortedTimes, setSortedTimes] = useState(opening_hours)

    function sortOpeningHours(a, b){
        return sortDayValues[a.day] - sortDayValues[b.day]
    }
    useEffect(() => {
       setSortedTimes(opening_hours.sort(sortOpeningHours)) 
    }, []);

    return ( 
        <div className='doctor-div'> 
            <h3 className='doctor-card-header'>{name}</h3>
            <div className='address-div'>
                <h3 className='doctor-card-header'>Address:</h3>
                <h4 className='address-text'>{address.district}</h4>
                <h4 className='address-text'>{address.line_1}</h4>
                <h4 className='address-text'>{address.line_2}</h4>
            </div>
            <h4 className='opening-times-header'>Opening Times:</h4>
            <div className='times-div'>
                {
                    sortedTimes.map((hours) => (
                       (<TimeCard key={hours.day} hours={hours}/>) 
                    ))
                }
            </div>
            <div>
                <BookingForm times={sortedTimes} date={date} id={id}/>
            </div>
        </div>
     )
}

export default DoctorCard