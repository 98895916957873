export const apiKey = "5404f0cc-fb98-476f-a3fe-cc608023e149"

export const apiURL = "https://bowtie-fe-assignment-api.onrender.com"

export const sortDayValues = {
    "SUN": 0,
    "MON": 1,
    "TUE": 2,
    "WED": 3,
    "THU": 4,
    "FRI": 5,
    "SAT": 6,  
}