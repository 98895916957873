import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Nav from '../components/Nav';
import DoctorCard from '../components/DoctorCard';
import { apiKey } from '../helpers/constants.js';
import '../styles/home.css'

const Home = () => {
    const [doctorsList, setDoctorsList] = useState([])
    const [date, setDate] = useState(null)    

    useEffect(() => {
        async function getDoctors(){
            const config = {
                method: 'get',
                url: 'https://bowtie-fe-assignment-api.onrender.com/doctor',
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': apiKey
                }
            }
            const response = await axios(config)
            setDoctorsList(response.data)
        }
        getDoctors()
    }, []);

    useEffect(() => {
        const todaysDate= new Date()
        const dateObject = {
            day: todaysDate.getDate(),
            month: (todaysDate.getMonth()+1),
            year: todaysDate.getFullYear(),
            time: `${todaysDate.getHours()}:${todaysDate.getMinutes()}`
        }
        setDate({dateObject})
    }, []); 

    return (
        <>
            <Nav/>
            <div className='body'>
                <ul>
                    {
                        doctorsList.map((doctor) => (
                            (<DoctorCard key={doctor.id} id={doctor.id} name={doctor.name} address={doctor.address} opening_hours={doctor.opening_hours} date={date.dateObject}/>)
                        ))
                    }
                </ul>
            </div>
        </>
    ) }
export default Home;